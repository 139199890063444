.page_name_home {
  .info-section {
    @apply py-8;

    &__title {
      @apply font-thin text-4xl;
    }

    &__body {
      @apply font-normal text-xl;
    }

    &_name {
      &_featured-stats {
        .info-section {
          &__column {
            &:last-child {
              .info-section {
                &__block {
                  &__title {
                    span {
                      @apply text-xl;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &_grid {
        .info-section {
          &__column {
            &:last-child {
              .info-section {
                &__block {
                  &__title {
                    @apply text-5xl;
                  }
                }
              }
            }
          }
        }
      }

      &_hero {
        .info-section {
          &__container {
            @apply h-[512px] py-16;
          }
      
          &__container__column-spans {
            @apply lg:col-span-12 lg:col-start-1;
          }
      
          &__header {
            @apply mb-0 overflow-hidden px-0 self-end;
            @apply lg:self-center;
          }

          &__title {
            @apply max-w-xl text-left;
          }
        }

        &--has-background {
          @apply mt-0;
        }
      }
    }
  }
}
